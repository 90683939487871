export default class EquipmentType {
    // Initialize Angular Config
    static CONFIG = {
        name: 'equipmentType',
        route: '/admin/equipmentType/',
        viewTemplate: require('./equipmentType.html'),
        controllerClass: EquipmentType,
        requireADLogin: true
    };

    constructor($scope, $filter, $uibModal, $q, $location, $routeParams, common, datacontext, hostConfig) {
        'ngInject';
        this.common = common;
        this.title = 'equipmentType';
        this.viewTitle = 'Equipment Type Maintenace';
        this.equipmentTypes = [];
        this.filteredEquipmentTypes = [];
        this.equipmentTypeName = '';
        this.newEquipmentTypeName = '';
        this.submitted = false;
        this.currentPage = 1;
        this.itemsPerPage = 1000;
        this.datacontext = datacontext;
        this.$scope = $scope;
        this.$uibModal = $uibModal;
     
        this.activate();
    }

    activate() {
        var vm = this;
        vm.common.activateController([vm.init()], vm.title, { viewTitle: vm.viewTitle });
    }

    init() {
        var vm = this;
        return vm.datacontext.getEquipmentTypes().then(function (response) {
            if (!response || !response.data) {
                return;
            }

            vm.equipmentTypes = response.data;
            let begin = ((vm.currentPage - 1) * vm.itemsPerPage);
            let end = begin + vm.itemsPerPage;
            vm.filteredEquipmentTypes = vm.equipmentTypes.slice(begin, end);
        });
    }

    save() {
        var vm = this;
        vm.submitted = true;
        if (!vm.$scope.equipmentTypeForm.$invalid) {
            if (vm.equipmentTypes.length) {
                for (var i = 0, len = this.equipmentTypes.length; i < len; i++) {
                    if (vm.equipmentTypes[i].equipmentTypeName.toUpperCase() === vm.newEquipmentTypeName.toUpperCase()) {
                        let modalData = {
                            equipmentTypeName: vm.newEquipmentTypeName
                        };

                        vm.$uibModal.open({
                            template: require('./duplicateEquipmentTypeModal.html'),
                            controller: 'duplicateEquipmentTypeModal',
                            controllerAs: '$ctrl',
                            size: 'md',
                            resolve: {
                                modalContext: () => modalData
                            }
                        });
                        return;
                    }
                }
            }
        }
        vm.datacontext.addEquipmentType(vm.newEquipmentTypeName).then(function (response) {
            if (!response) {
                vm.common.returnHome();
            }
            vm.equipmentTypes.push(response.data);
            vm.pageChanged();
        });
    }

    deleteEquipmentType(equipmentTypeName) {
        if (confirm("Are you sure you want to delete the Equipment Type '" + equipmentTypeName + "'?")) {
            var vm = this;
            return vm.datacontext.deleteEquipmentType(equipmentTypeName).then(function (response) {
                if (!response) {
                    vm.common.returnHome();
                }
                for (let i = 0; i < vm.equipmentTypes.length; i++) {
                    if (vm.equipmentTypes[i].equipmentTypeName == equipmentTypeName) {
                        vm.equipmentTypes.splice(i, 1);
                        break;
                    }
                }
                vm.pageChanged();
            });
        }
    }

    pageChanged() {
        var vm = this;
        let begin = ((vm.currentPage - 1) * vm.itemsPerPage);
        let end = begin + vm.itemsPerPage;
        vm.filteredEquipmentTypes = vm.equipmentTypes.slice(begin, end);
    }
}

var DuplicateEquipmentTypeModalController = function ($scope, $modalInstance, modalContext) {
    $scope.equipmentTypeName = modalContext.equipmentTypeName;

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    }
};