// NOTE:  Registered and used in Angular.
// noinspection JSUnusedGlobalSymbols
export default class ShellDirective {
    static name(){ return 'shell'; }

    static directive() {
        'ngInject';

        return {
            restrict: 'E',
            scope: {},
            // ReSharper disable once UseOfImplicitGlobalInFunctionScope
            //This forces the HTML to be included in the Javascript
            template: require('./shell.html'),
            controller: 'Shell',
            controllerAs: '$ctrl'
        };
    }
}