export default class UserFactory {
	static config = {
		name: 'userFactory',
		serviceClass: UserFactory
	}
	
	constructor() {
		this.data =
			{
				isCached: false,
				userId: '',
				role: '',
				country: '',
				IsInternal: false
			};
	}

	ClearCache() {
		var vm = this;
		vm.data =
			{
				isCached: false,
				userId: '',
				role: '',
				country: '',
				searchcountry: '',
				IsInternal: false
			};
	}

	getIsCached() {
		var vm = this;
		return vm.data.isCached;
	}
	setIsCached() {
		var vm = this;
		vm.data.isCached = true;
	}
	getUserId() {
		var vm = this;
		return vm.data.userId;
	}
	setUserId(userId) {
		var vm = this;
		vm.data.userId = userId;
	}
	getRole() {
		var vm = this;
		return vm.data.role;
	}
	setRole(role) {
		var vm = this;
		vm.data.role = role;
	}
	getCountry() {
		var vm = this;
		return vm.data.country;
	}
	setCountry(country) {
		var vm = this;
		vm.data.country = country;
	}
	getSearchCountry() {
		var vm = this;
		return vm.data.searchcountry;
	}
	setSearchCountry(searchcountry) {
		var vm = this;
		vm.data.searchcountry = searchcountry;
	}
	getIsInternal() {
		var vm = this;
		return vm.data.IsInternal;
	}
	setIsInternal(isInternal) {
		var vm = this;
		vm.data.IsInternal = isInternal;
	}


}
