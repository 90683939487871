// NOTE:  Registered and used in Angular.
// noinspection JSUnusedGlobalSymbols
export default class Shell {

	//Initialize Angular Config
	static CONFIG = {
		name: 'Shell',
		viewTemplate: require('./shell.html'),
		controllerClass: Shell
	};

	constructor($rootScope, $scope, $http, $location, $route, $window, common,
		hostConfig, datacontext, userFactory) {
		// noinspection BadExpressionStatementJS
		'ngInject';
		//this.logSuccess = common.logger.getLogFn(this.name, 'success');
		this.events = {
			controllerActivateSuccess: 'controller.activateSuccess',
			spinnerToggle: 'spinner.toggle',
			controllerTitleUpdate: 'controller.titleUpdate'
		};
		this.datacontext = datacontext;
		this.userFactory = userFactory;
		this.$scope = $scope;
		this.$location = $location;
		this.busyMessage = 'Working for you...';
		this.isBusy = true;
		this.showSplash = true;
		this.isIE9 = $window.isIE9;
		this.common = common;
		this.current = $route.current;
        this.controllerId = this.name;
        this.$scope.showLoader = true;
		this.globalSearchText = '';
		//this.routes = routes;

		this.spinnerOptions =
			{
				radius: 40,
				lines: 7,
				length: 0,
				width: 30,
				speed: 1.7,
				corners: 1.0,
				trail: 100,
				color: '#F58A00'
			};

		this.pageHeader = 'SurplusInventory';
		this.showHeader = true;

		this.userId = '';
		this.role = '';
		this.userPicUrl = hostConfig.serviceLocation() + 'auth/pic';
		this.isMain = false;
		this.isInternal = false;



		var vm = this;
		$rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
			console.log(toState.url);
			console.log(toState.templateUrl);
		});

		$rootScope.$on('$routeChangeStart',
			function (event, next, current) {
                

				if (next.$$route && next.$$route.templateUrl &&
					next.$$route.templateUrl === 'app/views/main/main.html' && vm.role === 'sampler') {
					event.preventDefault();
					$location.path('/search/wells');
				}
			}
		);

		//TODO -- Is it needed
		$rootScope.$on('$routeChangeSuccess',
			function (event, current) {

				if (current.$$route && current.$$route.templateUrl &&
					current.$$route.templateUrl === 'app/views/main/main.html') {
					vm.isMain = true;

					if (vm.role === 'sampler')
						$location.path('/search/wells');
				}
				vm.globalSearchText = '';
			}
		);

		$rootScope.$on(vm.events.controllerActivateSuccess,
			function (event, data) {
				setTitle(data);

				if (data.controllerId !== vm.controllerId)
                    this.$scope.showLoader = false;
			}
		);

		$rootScope.$on(vm.events.spinnerToggle,
			function (event, data) {
                this.$scope.showLoader = data.show;
			}
		);

		$rootScope.$on(vm.events.controllerTitleUpdate,
			function (event, data) {
				setTitle(data);
			}
		);
		this.activate();
	}

	activate()
	{
		var vm = this;
		vm.common.activateController([this.initUser(), this.initRoles()], 'shell');
	}

	initRoles() { }

	initUser() {
		var vm = this;
        return vm.datacontext.getUser(vm.$location.host()).then(function (response) {
			//Call to Server and Get, Userid, User Display Name, Roles and Country
			if (!response.data || !response.data.isInternal) {
				vm.datacontext.getInternalUrl().then(function (urlResponse) {
					vm.internalUrl = urlResponse.data;
				});
				vm.isInternal = false;;
				vm.userFactory.setIsInternal(vm.isInternal);
				vm.userFactory.setSearchCountry(response.data.searchCountry);
				vm.userFactory.setIsCached();
				vm.datacontext.addTraffic(vm.$location.host());
			}
			else {
				vm.isInternal = true;
				vm.user = { id: response.data.userId, displayName: response.data.displayName };
				vm.role = response.data.role;

				vm.userFactory.setUserId(vm.user.id);
				vm.userFactory.setRole(vm.role);
				vm.userFactory.setCountry(response.data.country);
				vm.userFactory.setIsInternal(vm.isInternal);
				vm.userFactory.setSearchCountry(response.data.searchCountry);
				vm.userFactory.setIsCached();
				if (vm.role !== 'admin') {
					vm.datacontext.addTraffic(vm.$location.host());
				}
            }
		});
	}

	toggleDropdown(status) {
		var vm = this;
		//$event.preventDefault();
		//$event.stopPropagation();
		vm.isOpen = status;
	}

	toggleSpinner(on) {
		var vm = this;
		vm.isBusy = on;
	}

	goTo(path) {
		var vm = this;
		vm.$location.path(path);
	}

	isCurrent(route) {
		var vm = this;
		var active = route === vm.$location.path();
		return active;
	}

	setTitle(data) {
		var vm = this;
		if (data.context && data.context.viewTitle) {
			if (data.context.hideHeader && data.context.hideHeader === true) {
				vm.showHeader = false;
				return;
			}
			else
				vm.showHeader = true;

			vm.pageSubHeader = data.context.viewTitle;

			if (data.context.viewTitleClass)
				vm.subHeaderClass = data.context.viewTitleClass;
		}
		else {
			vm.pageHeader = vm.pageHeader;
			vm.pageSubHeader = '';
			vm.subHeaderClass = 'defaultSubheader';
		}
	}

}