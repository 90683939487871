import AppConfig from '../app.config';

export default function commonConfig( $provide, $httpProvider) {
	// noinspection BadExpressionStatementJS
	'ngInject';
	//config.controllerActivateSuccessEvent = config.events.controllerActivateSuccess;
	//config.spinnerToggleEvent = config.events.spinnerToggle;
	//config.controllerTitleUpdateEvent = config.events.controllerTitleUpdate;

	$httpProvider.interceptors.push('myHttpInterceptor');

	$provide.factory('myHttpInterceptor', function ($q, logger)
	{
		// noinspection BadExpressionStatementJS
		'ngInject';
		//var logError = logger.getLogFn('app', 'error');
		//var logSuccess = logger.getLogFn('app', 'success');

		return {
			'response': function (response) {
				switch (response.config.method) {
					//case 'POST':
					//    logSuccess('Save Successful');
					//    break;
					case 'PUT':
						//logSuccess('Update Successful');
						break;
					case 'DELETE':
						//logSuccess('Delete Successful');
						break;
				}

				return response;
			},
			'responseError': function (rejection) {
				if (!rejection.data) {
					rejection.data = {};
				}
				var errorMsg = (rejection.data.message || rejection.message);

				var msg = AppConfig.config.appErrorPrefix + 'Error retrieving data from web API.';
				if (errorMsg !== undefined && errorMsg)
					msg += ' ' + errorMsg;
				//logError(msg, rejection);

				return $q.reject(rejection);
			}
		}
	});
};
