import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'animate.css/animate.css';
import 'angular-state-loader/angular-state-loader.css'
import 'font-awesome/css/font-awesome.css';

import '../styles/conocophillips-theme.css';
import '../styles/app.css'
import angular from 'angular';
import ngAnimate from 'angular-animate';
import uiRouter from 'angular-ui-router';
import angularbootstrap from 'angular-ui-bootstrap';
import bootstrap from 'bootstrap';
import angularUi from 'angular-ui';
import $route from 'angular-route';
// noinspection ES6UnusedImports
import angularStateLoader from 'angular-state-loader';
import adalAngular from './components/modular-adal-angular';

import logging from './app.config.logging';
import routing from './app.config.routing';
import authentication from './app.config.authentication';
import commonConfig from './app.config.commonConfig';
import startup from './app.startup';
import Init from './common/init.service';


window.Logging = {
    level: 3,
    log: function (message) {
        console.log(message);
    }
};

let angularModules = [
    // Angular modules
    ngAnimate,
    $route,
    // 3rd Party modules
    uiRouter,
    angularbootstrap,
    ngAnimate,
    angularUi,
    'ec.stateloader'
];

let internalUrls = ["localhost:8080", "surplusinventory-int-t.ca.cloudcopapps.net", "surplusinventory-int.ca.cloudcopapps.net"];
if (internalUrls.indexOf(location.host) > -1) {
    // Startup Angular
    angularModules.push(adalAngular);
    angular.module('app', angularModules)
        .config(logging)
        .config(routing)
        .config(authentication)
        .config(commonConfig)
        .run(startup);
} else {
    angular.module('app', angularModules)
        .config(logging)
        .config(routing)
        .config(commonConfig);
}
// Services
Init.serviceInit(require('./common/logger.service').default.CONFIG);
Init.serviceInit(require('./services/datacontext.service').default.CONFIG);
Init.serviceInit(require('./common/hostConfig').default.config);
Init.serviceInit(require('./common/common').default.config);
Init.serviceInit(require('./services/models/trafficFactory').default.config);
Init.serviceInit(require('./services/models/userFactory').default.config);
Init.serviceInit(require('./services/models/searchResultFactory').default.config);


// Directives
Init.directiveInit(require('./views/shell/shell.directive'));
//Init.directiveInit(require('./directives/lineChart.directive'));
//Init.directiveInit(require('./common/copConfirm.directive'));

// Controllers
Init.shellControllerInit(require('./views/shell/shell.controller').default.CONFIG);
Init.controllerInit(require('./views/main/main.controller').default.CONFIG);
Init.controllerInit(require('./views/listings/viewListing.controller').default.CONFIG);
Init.controllerInit(require('./views/listings/Listing.controller').default.CONFIG);
Init.controllerInit(require('./views/listings/search.controller').default.CONFIG);
Init.controllerInit(require('./views/listings/exportToExcelModal.controller').default.CONFIG);
Init.controllerInit(require('./views/listings/contactInfoModal.controller').default.CONFIG);
Init.controllerInit(require('./views/admin/equipmentType.controller').default.CONFIG);
Init.controllerInit(require('./views/admin/duplicateEquipmentTypeModal.controller').default.CONFIG);


export default 'app';