import commonConfig from '../app.config.commonConfig';
export default class Common {
	static config = {
		name:'common',
		serviceClass: Common
	}
	constructor( $q, $rootScope, $timeout, $location, $window, logger)
	{
		'ngInject';
		
		this.$q = $q;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.$location = $location;
		this.$window = $window;
		this.logger = logger;
		this.commonConfig = commonConfig;
	}

	activateController(promises, controllerId, context)
	{
		var vm = this;
		if(promises)
			return vm.$q.all(promises).then(function(eventArgs)
			{
				var event = {controllerId: controllerId};

				//Pass context in data
				if(context)
					event.context = context;

				//vm.$broadcast(vm.commonConfig.config.controllerActivateSuccessEvent, event);
			});
		else
		{
			var data = {controllerId: controllerId};

			//Pass context in data
			if(context)
				data.context = context;

			//vm.$broadcast(vm.commonConfig.config.controllerActivateSuccessEvent, data);

			return null;
		}
	}

	confirm(header, message, callBackConfirm, callBackCancel, confirmButtonName, cancelButtonName)
	{
		var vm = this;
		message = message || 'Are you sure?';
		header = header || 'Confirm';
		confirmButtonName = confirmButtonName || 'Ok';
		cancelButtonName = cancelButtonName || 'Cancel';

		var modalInstanceCtrl = function($scope, $modalInstance)
		{
			vm.$scope.ok = function() {$modalInstance.close();};
			vm.$scope.cancel = function() {$modalInstance.dismiss('cancel');};
		};

		var modalHtml = '<div class="modal-body"><div class="container-fluid">' +
			'<div class="row"><div class="col-xs-10"><p>' + header + '</p></div>' +
			'<div class="col-xs-2 text-right"><button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">×</span></button></div></div>' +
			'<div class="row"><div class="col-xs-12"><small>' + message + '<small></div></div>';
		modalHtml +=
			'<div class="row" style="padding-top:15px;"><div class="col-xs-12"><button class="btn btn-primary" ng-click="ok()">' +
			confirmButtonName +
			'</button><button class="btn btn-default" style="margin-left:10px;" ng-click="cancel()">' +
			cancelButtonName + '</button></div></div></div></div>';

		var modalInstance = $modal.open({
				backdrop: 'static',
				template: modalHtml,
				controller: ['$scope', '$modalInstance', modalInstanceCtrl]
			});

		return modalInstance.result.then(function()
			{
				return callBackConfirm();
			}, function()
			{
				return callBackCancel();
			});
	}

	toggleSpinner(data)
	{
		//$broadcast(commonConfig.config.spinnerToggleEvent, data);
	}

	updateTitle(data)
	{
		//$broadcast(commonConfig.config.controllerTitleUpdateEvent, {context: data});
	}

	generateGuid()
	{
		function s4()
		{
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		};

		return s4() + s4()
			+ '-' + s4()
			+ '-' + s4()
			+ '-' + s4()
			+ '-' + s4()
			+ s4() + s4();
	}

	$broadcast()
	{
		return $rootScope.$broadcast.apply($rootScope, arguments);
	}

	returnHome()
	{
		var vm = this;
		vm.$location.path('/');
		return;
	}

	refreshSize()
	{
		angular.element($window).resize();
	}
	
	
	
	
	
	
}



//(function () {
//    'use strict';

//    // Define the common module 
//    // Contains services:
//    //  - common
//    //  - logger
//    //  - spinner
//    var commonModule = angular.module('common', []);

//    // Must configure the common service and set its 
//    // events via the commonConfigProvider
//    commonModule.provider('commonConfig', function () {
//        this.config = {
//            // These are the properties we need to set
//            //controllerActivateSuccessEvent: '',
//            //spinnerToggleEvent: ''
//        };

//        this.$get = function () {
//            return {
//                config: this.config
//            };
//        };
//    });

//    commonModule.factory('common',
//        ['$modal', '$q', '$rootScope', '$timeout', '$location', '$window', 'commonConfig', 'logger', 'toaster', common]);

//    function common($modal, $q, $rootScope, $timeout, $location, $window, commonConfig, logger, toaster) {
//        var throttles = {};

//        var service = {
//            // common angular dependencies
//            //$broadcast: $broadcast,
//            $q: $q,
//            $timeout: $timeout,
//            // generic
//            activateController: activateController,
//            confirm: confirm,
//            generateGuid: generateGuid,
//            logger: logger, // for accessibility
//            toggleSpinner: toggleSpinner,
//            updateTitle: updateTitle,
//            returnHome: returnHome,
//            refreshSize: refreshSize,
//            toaster: toaster
//        };

//        return service;

//        function activateController(promises, controllerId, context)
//        {
//            if (promises)
//                return $q.all(promises).then(function (eventArgs)
//                {
//                    var event = { controllerId: controllerId };

//                    //Pass context in data
//                    if(context)
//                        event.context = context;

//                    $broadcast(commonConfig.config.controllerActivateSuccessEvent, event);
//                });
//            else
//            {
//                var data = { controllerId: controllerId };

//                //Pass context in data
//                if (context)
//                    data.context = context;

//                $broadcast(commonConfig.config.controllerActivateSuccessEvent, data);

//                return null;
//            }
//        }

//        function confirm(header, message, callBackConfirm, callBackCancel, confirmButtonName, cancelButtonName)
//        {
//            message = message || 'Are you sure?';
//            header = header || 'Confirm';
//            confirmButtonName = confirmButtonName || 'Ok';
//            cancelButtonName = cancelButtonName || 'Cancel';

//            var modalInstanceCtrl = function ($scope, $modalInstance)
//            {
//                $scope.ok = function () { $modalInstance.close(); };
//                $scope.cancel = function () { $modalInstance.dismiss('cancel'); };
//            };

//            var modalHtml = '<div class="modal-body"><div class="container-fluid">' +
//                '<div class="row"><div class="col-xs-10"><p>' + header + '</p></div>' +
//                '<div class="col-xs-2 text-right"><button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">×</span></button></div></div>' +
//                '<div class="row"><div class="col-xs-12"><small>' + message + '<small></div></div>';
//            modalHtml += '<div class="row" style="padding-top:15px;"><div class="col-xs-12"><button class="btn btn-primary" ng-click="ok()">' + confirmButtonName + '</button><button class="btn btn-default" style="margin-left:10px;" ng-click="cancel()">' + cancelButtonName + '</button></div></div></div></div>';

//            var modalInstance = $modal.open({
//                backdrop: 'static',
//                template: modalHtml,
//                controller: ['$scope', '$modalInstance', modalInstanceCtrl]
//            });

//            return modalInstance.result.then(function ()
//            {
//                return callBackConfirm();
//            }, function ()
//            {
//                return callBackCancel();
//            });
//        }

//        function toggleSpinner(data)
//        {
//            $broadcast(commonConfig.config.spinnerToggleEvent, data);
//        }

//        function updateTitle(data)
//        {
//            $broadcast(commonConfig.config.controllerTitleUpdateEvent, { context: data });
//        }

//        function generateGuid()
//        {
//            function s4()
//            {
//                return Math.floor((1 + Math.random()) * 0x10000)
//                           .toString(16)
//                           .substring(1);
//            };

//            return s4() + s4()
//                    + '-' + s4()
//                    + '-' + s4()
//                    + '-' + s4()
//                    + '-' + s4()
//                    + s4() + s4();
//        }

//        function $broadcast() {
//            return $rootScope.$broadcast.apply($rootScope, arguments);
//        }

//        function returnHome()
//        {
//            $location.path('/#/');
//            return;
//        }

//        function refreshSize()
//        {
//            angular.element($window).resize();
//        }
//    }
//})();