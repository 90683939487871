export default class Main {
    //Initialize Angular Config
    static CONFIG = {
        name: 'main',
        route: '/',
        viewTemplate: require('./main.html'),
        controllerClass: Main,
        requireADLogin: true
    };

    constructor($scope, $filter, $q, $location, datacontext, hostConfig, common, userFactory, trafficFactory) {
        'ngInject';
        this.hostConfig = hostConfig;
        this.title = 'main';
        this.viewTitle = 'Main';
        this.parent = $scope.$parent;
        this.isInternal = false;
        this.apiPath = this.hostConfig.serviceLocation() + 'search/';
        this.preview = {};
        this.lastTenListings = [];
        this.lastTenSoldListings = [];
        this.mostPopularListings = [];
        this.mostRecentListings = [];
        this.soldItemsToDate = 0;
        this.soldItemsThisMonth = 0;
        this.externalHitsThisWeek = 0;
        this.externalHitsThisMonth = 0;
        this.externalHitsThisYear = 0;
        this.externalHitsTotal = 0;
        this.internalHitsThisWeek = 0;
        this.internalHitsThisMonth = 0;
        this.internalHitsThisYear = 0;
        this.internalHitsTotal = 0;
        this.trafficFactory = trafficFactory;
        this.userFactory = userFactory;
        this.common = common;
        this.datacontext = datacontext;
        this.$location = $location;
        this.isInternal = this.userFactory.getIsInternal();
        this.country = userFactory.getCountry();
        this.activate();


    }

    activate() {

        var vm = this;

        vm.common.activateController([vm.init()], vm.title, { viewTitle: vm.viewTitle });
        vm.parent.showLoader = false;

    }


    init() {
        var vm = this;
        if (!vm.trafficFactory.getIsCached()) {
            return vm.datacontext.getHomeData(vm.$location.host()).then(function (response) {
                vm.isInternal = response.data.isInternal;
                if (vm.isInternal) {
                    //vm.mostPopularListings = response.data.homeListingData.mostPopularListings;
                    //vm.lastTenSoldListings = response.data.mySoldListings;

                    vm.lastTenListings = response.data.homeListingData.mylastTenListings;
                    vm.soldItemsToDate = response.data.homeListingData.soldItemsToDate;
                    vm.soldItemsThisMonth = response.data.homeListingData.soldItemsThisMonth;
                    vm.internalHitsThisWeek = response.data.trafficStats.internalHitsThisWeek;
                    vm.internalHitsThisMonth = response.data.trafficStats.internalHitsThisMonth;
                    vm.internalHitsThisYear = response.data.trafficStats.internalHitsThisYear;
                    vm.internalHitsTotal = response.data.trafficStats.internalHitsTotal;
                    vm.externalHitsThisWeek = response.data.trafficStats.externalHitsThisWeek;
                    vm.externalHitsThisMonth = response.data.trafficStats.externalHitsThisMonth;
                    vm.externalHitsThisYear = response.data.trafficStats.externalHitsThisYear;
                    vm.externalHitsTotal = response.data.trafficStats.externalHitsTotal;

                    //vm.trafficFactory.setMostPopularListings(vm.mostPopularListings);
                    //vm.trafficFactory.setLastTenSoldListings(vm.lastTenSoldListings);
                    vm.trafficFactory.setLastTenListings(vm.lastTenListings);
                    vm.trafficFactory.setsoldItemsToDate(vm.soldItemsToDate);
                    vm.trafficFactory.setSoldItemsThisMonth(vm.soldItemsThisMonth);
                    vm.trafficFactory.setInternalHitsThisWeek(vm.internalHitsThisWeek);
                    vm.trafficFactory.setInternalHitsThisMonth(vm.internalHitsThisMonth);
                    vm.trafficFactory.setInternalHitsThisYear(vm.internalHitsThisYear);
                    vm.trafficFactory.setInternalHitsTotal(vm.internalHitsTotal);
                    vm.trafficFactory.setExternalHitsThisWeek(vm.externalHitsThisWeek);
                    vm.trafficFactory.setExternalHitsThisMonth(vm.externalHitsThisMonth);
                    vm.trafficFactory.setExternalHitsThisYear(vm.externalHitsThisYear);
                    vm.trafficFactory.setExternalHitsTotal(vm.externalHitsThisYear);
                    vm.trafficFactory.setIsCached();
                    vm.country = response.data.country;


                    // Seperating since there are some issue faced while getting this record.
                    vm.datacontext.getmostPopularListings(vm.country).then(function (response) {
                        vm.mostPopularListings = response.data;
                        vm.trafficFactory.setMostPopularListings(vm.mostPopularListings);
                    });
                    vm.datacontext.getTenMySoldListing().then(function (response) {
                        vm.lastTenSoldListings = response.data;
                        vm.trafficFactory.setLastTenSoldListings(vm.lastTenSoldListings);
                    });
                }
                else {
                    vm.mostRecentListings = response.data.mostRecentListings;
                    vm.trafficFactory.setMostRecentListings(vm.mostRecentListings);
                    vm.country = response.data.country;
                    vm.trafficFactory.setIsCached();
                }
                vm.userFactory.setCountry(vm.country);
                vm.userFactory.setIsInternal(vm.isInternal);

            });
        }
        else {
            if (vm.isInternal) {

                vm.lastTenListings = vm.trafficFactory.getLastTenListings();
                vm.soldItemsToDate = vm.trafficFactory.getsoldItemsToDate();
                vm.soldItemsThisMonth = vm.trafficFactory.getSoldItemsThisMonth();
                vm.internalHitsThisWeek = vm.trafficFactory.getInternalHitsThisWeek();
                vm.internalHitsThisMonth = vm.trafficFactory.getInternalHitsThisMonth();
                vm.internalHitsThisYear = vm.trafficFactory.getInternalHitsThisYear();
                vm.internalHitsTotal = vm.trafficFactory.getInternalHitsTotal();
                vm.externalHitsThisWeek = vm.trafficFactory.getExternalHitsThisWeek();
                vm.externalHitsThisMonth = vm.trafficFactory.getExternalHitsThisMonth();
                vm.externalHitsThisYear = vm.trafficFactory.getExternalHitsThisYear();
                vm.externalHitsTotal = vm.trafficFactory.getExternalHitsTotal();

                vm.lastTenSoldListings = vm.trafficFactory.getLastTenSoldListings();
                vm.mostPopularListings = vm.trafficFactory.getMostPopularListings();

            }
            else {
                vm.mostRecentListings = vm.trafficFactory.getMostRecentListings();
            }
            
        }
      
    }

    showPreview(id) {
        var vm = this;
        return vm.datacontext.getListing(id).then(function (response) {
            vm.preview = response.data;


        });
       
    }
}