export default class contactInfoModal {
    static CONFIG = {
        name: 'contactInfoModal',
        controllerClass: contactInfoModal
    };

    constructor($scope, $uibModalInstance, modalContext) {
        'ngInject';
        
        this.$uibModalInstance = $uibModalInstance;
        this.header = modalContext.header;
        this.emailAddress = modalContext.emailAddress;
    }

    close() {
        var vm = this;
        vm.$uibModalInstance.close();
    }

    cancel() {
        var vm = this;
        vm.$uibModalInstance.dismiss('cancel');
    }

    


}