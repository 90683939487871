export default class Search {
    static CONFIG = {
        name: 'search',
        route: '/search/:mode?',
        viewTemplate: require('./search.html'),
        controllerClass: Search,
        requireADLogin: true
    };

    constructor($scope, $filter, $q, $location, $uibModal, $routeParams, common, datacontext, hostConfig,
        searchResultFactory, userFactory) {
        'ngInject';
        this.title = 'search';
        this.viewTitle = 'Search';
        this.parent = $scope.$parent;

        this.searchResultFactory = searchResultFactory;
        this.common = common;
        this.userFactory = userFactory;
        this.$location = $location;
        this.datacontext = datacontext;
        this.$uibModal = $uibModal;


        this.isInternal = false;
        this.apiPath = hostConfig.serviceLocation() + 'search/';
        this.equipmentTypes = [];
        this.conditions = [
            { condition: 'A - New 100%' }, { condition: 'B - Good / used 75%' },
            { condition: 'C - Usable with minor repair 50%' }, { condition: 'D - Usable with major repair 25%' },
            { condition: 'E - Junk' }
        ];
        this.listingStatuses = ['Active', 'Reserved'];
        this.ansis = [{ ansi: 150 }, { ansi: 300 }, { ansi: 600 }, { ansi: 900 }];
        this.countries = [{ countryName: 'Canada' }, { countryName: 'United States' }];
        this.numberOfRecords = [10, 15, 20, 25, 30];
        this.searchResults = [];
        this.searchText = '';
        this.listing = {};
        this.currentPage = 1;
        this.itemsPerPage = 10;
        this.country = '';
        this.reservedNames = [];
        this.maxSize = 10;
        this.numberOfPages = 0;

        this.role = userFactory.getRole();
        this.isInternal = userFactory.getIsInternal();
        this.country = userFactory.getSearchCountry();
        this.listing.country = userFactory.getSearchCountry();

        this.activate();
    }

    activate() {
        var vm = this;
        if (vm.searchResultFactory.getIsCached()) {
            vm.searchResults = vm.searchResultFactory.getSearchResult();
            vm.listingStatuses = vm.searchResultFactory.getListStatus();
            vm.equipmentTypes = vm.searchResultFactory.getEquipmentTypes();
            vm.isInternal = vm.searchResultFactory.getIsInternal();
            vm.searchText = vm.searchResultFactory.getSearchText();
            vm.listing = vm.searchResultFactory.getSearchListing();
            vm.currentPage = vm.searchResultFactory.getSearchCurrentPage();
            vm.itemsPerPage = vm.searchResultFactory.getListingsPerPage();
            vm.country = vm.listing.country;
            vm.reservedNames = vm.searchResultFactory.getReservedNames();

            vm.pageChanged();
            vm.common.toggleSpinner({
                show: false
            });
            //searchResultFactory.ClearCache();
        }
        else {
            vm.common.activateController([vm.initEquipmentTypes(), vm.initRoles(), vm.init()], 'search', {
                viewTitle: vm.viewTitle
            });
        }
    }

    init() {
        var vm = this;
        if (vm.isInternal) {
            vm.initReservedUsers();
        }
        if (!vm.userFactory.getIsCached()) {
            return vm.datacontext.getSearchData(vm.$location.host()).then(function (response) {
                vm.isInternal = response.data.isInternal;
                vm.country = response.data.country;
                vm.listing.country = vm.country;
                return vm.startSearch();
            });
        }
        else
            return vm.startSearch();
    }

    initRoles() {
        var vm = this;
        if (!vm.userFactory.getIsCached()) {
            return vm.datacontext.getRoles().then(function (result) {
                vm.role = result.data;
                if (vm.role === 'admin')
                    vm.listingStatuses = ['Active', 'Inactive', 'Reserved', 'Sold'];
            });
        }
        else {
            if (vm.role === 'admin')
                vm.listingStatuses = ['Active', 'Inactive', 'Reserved', 'Sold'];
        }
    }

    initReservedUsers() {
        var vm = this;
        return vm.datacontext.getReservedNames().then(function (response) {
            vm.reservedNames = response.data;
        });
    }

    initEquipmentTypes() {
        var vm = this;
        return vm.datacontext.getEquipmentTypes().then(function (response) {
            vm.equipmentTypes = response.data;
        });
    }

    startSearch() {
        var vm = this;
        vm.common.toggleSpinner({ show: true });
        if (vm.isInternal) {
            if (vm.role === 'admin') {
                return vm.datacontext.searchAdmin(vm.listing, vm.searchText).then(function (response) {
                    if (!response || !response.data)
                        return;

                    vm.searchResults = response.data;
                    vm.pageChanged();
                    vm.common.toggleSpinner({ show: false });
                    vm.searchResultFactory.SetCache(true, vm.searchResults, vm.role, vm.listingStatuses,
                        vm.equipmentTypes, vm.isInternal, vm.searchText, vm.listing, vm.currentPage,
                        vm.itemsPerPage, vm.reservedNames);
                });
            }
            return vm.datacontext.searchInternal(vm.listing, vm.searchText).then(function (response) {
                if (!response || !response.data)
                    return;

                vm.searchResults = response.data;
                vm.pageChanged();
                vm.common.toggleSpinner({ show: false });
                vm.searchResultFactory.SetCache(true, vm.searchResults, vm.role, vm.listingStatuses,
                    vm.equipmentTypes, vm.isInternal, vm.searchText, vm.listing, vm.currentPage,
                    vm.itemsPerPage, vm.reservedNames);
            });
        }
        else {
            return vm.datacontext.searchExternal(vm.listing, vm.searchText).then(function (response) {
                if (!response || !response.data) {
                    return;
                }

                vm.searchResults = response.data;
                vm.pageChanged();
                vm.common.toggleSpinner({ show: false });
                vm.searchResultFactory.SetCache(true, vm.searchResults, vm.role, vm.listingStatuses,
                    vm.equipmentTypes, vm.isInternal, vm.searchText, vm.listing, vm.currentPage,
                    vm.itemsPerPage, vm.reservedNames);
            });
        }
    }

    listingPerPageChanged() {
        var vm = this;
        vm.currentPage = 1;
        vm.pageChanged();
    }

    pageChanged() {
        var vm = this;
        vm.numberOfPages = Math.ceil(vm.searchResults.length / vm.itemsPerPage);
        if (vm.currentPage > vm.numberOfPages)
            vm.currentPage = 1;
        var begin = ((vm.currentPage - 1) * vm.itemsPerPage);
        var end = begin + vm.itemsPerPage;
        vm.filteredSearchResults = vm.searchResults.slice(begin, end);
    }

    viewListing(id) {
        var vm = this;
        vm.searchResultFactory.SetCache(true, vm.searchResults, vm.role, vm.listingStatuses, vm.equipmentTypes,
            vm.isInternal, vm.searchText, vm.listing, vm.currentPage, vm.itemsPerPage, vm.reservedNames);
        vm.$location.path('/listing/view/' + id);
    }

    editListing(id) {
        var vm = this;
        vm.searchResultFactory.SetCache(true, vm.searchResults, vm.role, vm.listingStatuses, vm.equipmentTypes,
            vm.isInternal, vm.searchText, vm.listing, vm.currentPage, vm.itemsPerPage, vm.reservedNames);
        vm.$location.path('/listing/' + id);
    }

    resetSearchCriteria() {
        var vm = this;
        vm.searchResultFactory.ClearCache();
        vm.searchText = '';
        vm.listing = {};
        vm.country = vm.userFactory.getSearchCountry();
        vm.listing.country = vm.country;

        return vm.startSearch();
    }

    openModal() {
        var vm = this;
        let modalData = {
            header: 'Export to Excel',
            exportColumns: {
                ansi: true,
                area: true,
                condition: true,
                conditionComment: true,
                country: true,
                creditCoding: true,
                skidNumber: true,
                listingAgentId: true,
                description: true,
                equipmentType: true,
                isSweet: true,
                isInternal: true,
                id: true,
                listingStatus: true,
                location: true,
                manufacturer: true,
                modelNumber: true,
                isNew: true,
                otherInfo: true,
                gridLocation: true,
                publishedStatusDate: true,
                reservedStatusDate: true,
                reservedFor: true,
                sapEquipmentNumber: true,
                soldStatusDate: true,
                serialNumber: true,
                size: true,
                stateProvince: true,
                notificationNumber: true,
                internalHits: true,
                externalHits: true
            },
            apiPath: vm.apiPath,
            listing: vm.listing,
            searchText: vm.searchText,
            isAllSelected: true

        };
        vm.$uibModal.open({
            animation: true,
            template: require('./exportToExcelModal.html'),
            controller: 'exportToExcelModal',
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
                modalContext : () => modalData
            }
        });
    }
}