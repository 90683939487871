import AppConfig from '../app.config';

export default function authentication($httpProvider, adalAuthenticationServiceProvider) {
    // noinspection BadExpressionStatementJS
    'ngInject';

    let aadConfig = {
        instance: AppConfig.aadInstance,
        tenant: AppConfig.aadTenant,
        clientId: AppConfig.aadClientId,
        requireAdLogin: false,
        cacheLocation: 'localStorage',
        extraQueryParameter: 'nux=1',
        endpoints: AppConfig.aadRedirectEndpoints
    };

    adalAuthenticationServiceProvider.init(aadConfig, $httpProvider);
}