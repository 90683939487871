import appConfig from '../../app.config';

export default class Datacontext {
	static CONFIG = {
		name: 'datacontext',
		serviceClass: Datacontext
	};
	
	constructor($http, $q, $filter, hostConfig) {
		// noinspection BadExpressionStatementJS
		'ngInject';
		this.$q = $q;
		this.$http = $http;
		this.hostConfig = hostConfig;
	}

	getAuthenticated() {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'auth';
		var promise = vm.$http.get(path);

		return promise;
	}

	getUser(host) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'auth/userdata?host=' + host;
		var promise = vm.$http.get(path);

		return promise;
	}

	getRoles() {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'auth/group';
		var promise = vm.$http.get(path);

		return promise;
	}

	getFullname() {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'auth/fullname';
		var promise = vm.$http.get(path);

		return promise;
	}

	getInternalUrl() {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'auth/internalUrl';
		var promise = vm.$http.get(path);

		return promise;
	}

	getCountry() {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'auth/country';
		var promise = vm.$http.get(path);

		return promise;
	}

	getReservedNames() {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'listing/reserved';
		var promise = vm.$http.get(path);

		return promise;
	}

	getContactInfo(country) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'listing/contact/?country=' + country;
		var promise = vm.$http.get(path);

		return promise;
	}

	addEquipmentType(equipmentTypeName) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'equipmentType/?name=' + equipmentTypeName;
		var promise = vm.$http.post(path);

		return promise;
	}

	deleteEquipmentType(equipmentTypeName) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'equipmentType/?name=' + equipmentTypeName;
		var promise = vm.$http.delete(path);

		return promise;
	}

	getEquipmentTypes() {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'equipmentType';
		var promise = vm.$http.get(path);

		return promise;
	}

	searchAdmin(searchListing, searchText) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'search/?searchText=' + searchText;
		var promise = vm.$http.post(path, searchListing);

		return promise;
	}

	searchInternal(searchListing, searchText) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'search/internal/?searchText=' + searchText;
		var promise = vm.$http.post(path, searchListing);

		return promise;
	}

	searchExternal(searchListing, searchText) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'search/external/?searchText=' + searchText;
		var promise = vm.$http.post(path, searchListing);

		return promise;
	}

	getSearchData(host) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'search/data/?host=' + host;
		var promise = vm.$http.get(path);

		return promise;
	}

	searchAd(searchText) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'auth/searchad/?searchText=' + searchText;
		var promise = vm.$http.get(path);

		return promise;
	}

	getListing(id) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'listing/?id=' + id;
		var promise = vm.$http.get(path);

		return promise;
	}

	addListing(listing) {
		var vm = this;
		//Fix the datetime formatting to play nicely in the sandbox.
		//sampleModel.adjustDatesToUtc(sample);

		var path = vm.hostConfig.serviceLocation() + 'listing/noattachment';
		var promise = vm.$http.post(path, listing);

		return promise;
	}

	updateListing(listing) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'listing';
		var promise = vm.$http.put(path, listing);

		return promise;
	}

	updateImageNames(id, imageNames) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'listing/imagenames/?id=' + id;
		var promise = vm.$http.put(path, imageNames);

		return promise;
	}

	updateAttachmentNames(id, attachmentNames) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'listing/attachmentnames/?id=' + id;
		var promise = vm.$http.put(path, attachmentNames);

		return promise;
	}

	getFile(fileName) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'listing/file?filename=' + fileName;
		var promise = vm.$http.get(path);

		return promise;
	}

	deleteFile(listing, fileName) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'listing/deletefile?filename=' + fileName;
		var promise = vm.$http.post(path, listing);

		return promise;
	}

	getHomeData(host) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'home/?host=' + host;
		var promise = vm.$http.get(path);

		return promise;
	}

	getmostPopularListings(country) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'getmostpopularlistings/?country=' + country;
		var promise = vm.$http.get(path);

		return promise;
	}


	getTenMySoldListing() {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'getmytensoldlisting/';
		var promise = vm.$http.get(path);

		return promise;
	}


	addTraffic(host) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'traffic/?host=' + host;
		var promise = vm.$http.post(path);

		return promise;
	}

	addListingTraffic(id, host) {
		var vm = this;
		var path = vm.hostConfig.serviceLocation() + 'traffic/listing?id=' + id + '&host=' + host;
		var promise = vm.$http.post(path);

		return promise;
	}

	staticReturnPromise(returnValue) {
		var vm = this;
		var result = { data: returnValue };
		var deferred = $q.defer();

		deferred.resolve(result);

		return deferred.promise;
	}

	_queryFailed(error) {
		var vm = this;
		if (!error.data) { error.data = {}; }
		var errorMsg = (error.data.message || error.message);

		var msg = config.appErrorPrefix + 'Error retrieving data from web API.';
		if (errorMsg !== undefined && errorMsg)
			msg += ' ' + errorMsg;
		//logError(msg, error);
		throw error;
	}
}


