export default function startup($rootScope, $timeout, adalAuthenticationService) {
    'ngInject';
    let adalErrors = localStorage.getItem('adalErrors');

    let clearLocalStorage = function (event, errorDescription, errorType) {
        adalErrors++;
        if (adalErrors > 5) {
            alert('Unable to complete authentication.  Please contact IT support.');
        }
        else {
            localStorage.setItem('adalErrors', adalErrors);

            // Remove Adal local storage
            let arr = [];
            for (let i = 0; i < localStorage.length; i++) {
                if (localStorage.key(i).substring(0, 5) === 'adal.') {
                    arr.push(localStorage.key(i));
                }
            }
            for (let j = 0; j < arr.length; j++) {
                localStorage.removeItem(arr[j]);
            }

            $timeout(function () {
                adalAuthenticationService.login();
            }, 7000);
        }
    };

    // Handle ADAL token failures to avoid "refresh loops" in the browser when trying to acquire or renew a token
    // NOTE: This is only for AzureAD/cloud-based Authentication. Delete this if you are using Windows credentials (NTLM/Kerberose)
    $rootScope.$on('adal:acquireTokenFailure', function (event, errorDescription, errorType) {
        console.error('Error with ADAL: ' + errorType + ' loginInProgress: ' +
            adalAuthenticationService.loginInProgress() + ', ADAL Errors: ' + $rootScope.adalErrors);
        if ((errorType === 'login_required' && !adalAuthenticationService.loginInProgress()) ||
            errorType === 'Token Renewal Failed' ||
            errorDescription.includes('AADSTS50058') || errorType.includes('AADSTS50058')) {
            clearLocalStorage(event, errorDescription, errorType);
        }
    });

    // Calling out to ADAL login when a 401 is returned from the API to resolve token refresh
    $rootScope.$on('$stateChangeError', function handleError(event, toState, toParams, fromState, fromParams, error) {
        if (error.status === 401 && !adalAuthenticationService.loginInProgress()) {
            clearLocalStorage();
        }
    });
}