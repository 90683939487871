import toastr from 'toastr';

// NOTE:  Registered and used in Angular.
// noinspection JSUnusedGlobalSymbols
export default class Logger {
	constructor($log)
	{
		'ngInject';
		this.$log = $log;
	}

	//Initialize Angular Config
	static CONFIG = {
		name: 'logger',
		serviceClass: Logger
	};

	// noinspection JSUnusedGlobalSymbols
	log(message, data, source, showToast)
	{
		this.logIt(message, data, source, showToast, 'info');
	}

	logWarning(message, data, source, showToast)
	{
		this.logIt(message, data, source, showToast, 'warning');
	}

	logSuccess(message, data, source, showToast)
	{
		this.logIt(message, data, source, showToast, 'success');
	}

	logError(message, data, source, showToast)
	{
		this.logIt(message, data, source, showToast, 'error');
	}

	logIt(message, data, source, showToast, toastType)
	{
		let write = (toastType === 'error') ? this.$log.error : this.$log.log;
		source = source ? '[' + source + '] ' : '';
		write(source, message, data);

		showToast = (showToast === undefined) ? true : showToast;
		if(showToast)
		{
			if(toastType === 'error')
			{
				toastr.error(message);
			}
			else if(toastType === 'warning')
			{
				toastr.warning(message);
			}
			else if(toastType === 'success')
			{
				toastr.success(message);
			}
			else
			{
				toastr.info(message);
			}
		}
	}
}