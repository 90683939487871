import ListingModel from '../../services/models/listingModel';
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';
import Dropzone from 'dropzone';
import bsDialog from 'bootstrap-dialogs';
import 'jquery-ui/ui/widgets/sortable';

export default class Listing {
    static CONFIG = {
        name: 'listing',
        route: '/listing/:id?',
        viewTemplate: require('./listing.html'),
        controllerClass: Listing,
        requireADLogin: true
    };

    constructor($scope, $filter, $q, $stateParams, adalAuthenticationService, $location, $http, $timeout,
        $route, $window, common, datacontext, hostConfig) {
        'ngInject';
        this.title = 'listing';
        this.viewTitle = 'Listing';

        this.$stateParams = $stateParams;
        this.datacontext = datacontext;
        this.common = common;
        this.$scope = $scope;
        this.$location = $location;
        this.form = $scope.listingForm;
        this.$timeout = $timeout;

        this.parent = $scope.$parent;
        this.equipmentTypes = [];
        this.conditions = [
            { condition: 'A - New 100%' }, { condition: 'B - Good / used 75%' },
            { condition: 'C - Usable with minor repair 50%' }, { condition: 'D - Usable with major repair 25%' },
            { condition: 'E - Junk' }
        ];
        this.countries = [{ countryName: 'Canada' }, { countryName: 'United States' }];
        this.stateProvinces = [];
        this.usStates = [
            'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
            'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
            'Kansas',
            'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
            'Mississippi',
            'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
            'New York',
            'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
            'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
            'West Virginia', 'Wisconsin', 'Wyoming'
        ];
        this.caProvinces = [
            'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador',
            'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec',
            'Saskatchewan', 'Yukon'
        ];
        this.listingStatuses = [
            { listingStatus: 'Active' }, { listingStatus: 'Inactive' }, { listingStatus: 'Reserved' },
            { listingStatus: 'Sold' }
        ];
        this.ansis = [{ ansi: 150 }, { ansi: 300 }, { ansi: 600 }, { ansi: 900 }];
        this.listing = new ListingModel();
        this.listing.reservedFor = '';
        this.listingBackup = {};
        this.apiPath = hostConfig.serviceLocation() + 'listing/';
        this.fileOnServer = true;
        this.listingCreated = true;
        this.dateFormat = 'MMM D YYYY';

        var resource = adalAuthenticationService.getResourceForEndpoint(hostConfig.serviceLocation());
        this.tokenStored = adalAuthenticationService.getCachedToken(resource);


        $window.onbeforeunload = function () {
            if ($scope.listingForm && $scope.listingForm.$dirty) {
                return 'Any unsaved data will be lost. Are you sure you want to leave?';
            }
        }

        $scope.$on('$locationChangeStart', function (event, next, current) {
            if ($scope.listingForm.$dirty && !confirm('Any unsaved data will be lost. Are you sure you want to leave?')) {
                common.toggleSpinner({ show: false });
                event.preventDefault();
                $location.url($location.url(next).hash());




            }
        });

        var vm = this;
        try {
            vm.myDropzone = new Dropzone('#dropzoneArea', {
                renameFilename: function (filename) {
                    return new Date().getTime() + '_' + filename;
                },
                url: hostConfig.serviceLocation() + 'listing', // Set the url
                createImageThumbnails: true,
                withCredentials: true,
                uploadMultiple: true,
                parallelUploads: 20,
                previewTemplate: document.getElementById('previewTemplate').innerHTML,
                previewsContainer: '#imagePreviews', // Define the container to display the previews
                clickable: '#imageInputButton', // Define the element that should be used as click trigger to select files.
                acceptedFiles: '.jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,.GIF',
                dictRemoveFileConfirmation: 'Are you sure you want to remove the file?',
                dictInvalidFileType: 'Image must be of the file type .jpeg, .jpg, .png, or .gif.',
                headers: { Authorization: 'Bearer ' + vm.tokenStored }
            });
        }
        catch (e) {
            $window.location.reload();
        }

        $('#dropzoneArea').sortable({
            items: '.dz-preview',
            cursor: 'move',
            opacity: 0.5,
            containment: 'parent',
            distance: 20,
            tolerance: 'pointer',
            update: function () {
                var currentQueue = vm.myDropzone.files;
                var newQueue = [];
                $('#dropzoneArea .dz-preview .dz-filename [data-dz-name]').each(function (count, element) {
                    var name = element.innerHTML;
                    currentQueue.forEach(function (file) {
                        if (file.name === name) {
                            newQueue.push(file.name);
                        }
                    });
                });
                // Update the new file order in DB
                if (vm.listing.id) {
                    datacontext.updateImageNames(vm.listing.id, newQueue).then(function (response) {
                        if (!response) {
                            return;
                        }
                        vm.listing.imageNames = response.data.imageNames;
                    });
                }
            }
        });

        try {
            vm.myDropzoneAttachment = new Dropzone('#dropzoneAreaAttachment', {
                url: hostConfig.serviceLocation() + 'listing', // Set the url
                uploadMultiple: true,
                withCredentials: true,
                parallelUploads: 20,
                previewTemplate: document.getElementById('attachmentPreviewTemplate').innerHTML,
                previewsContainer: '#attachmentPreviews', // Define the container to display the previews
                clickable: '#attachmentInputButton', // Define the element that should be used as click trigger to select files.
                dictRemoveFileConfirmation: 'Are you sure you want to remove the file?',
                headers: { Authorization: 'Bearer ' + vm.tokenStored }
            });
        }
        catch (e) {
            $window.location.reload();
        }

        $('#dropzoneAreaAttachment').sortable({
            items: '.dz-preview',
            cursor: 'move',
            opacity: 0.5,
            containment: 'parent',
            distance: 20,
            tolerance: 'pointer',
            update: function () {
                var currentQueueAttachment = vm.myDropzoneAttachment.files;
                var newQueueAttachment = [];
                $('#dropzoneAreaAttachment .dz-preview .dz-filename [data-dz-name]').each(
                    function (count, element) {
                        var name = element.innerHTML;
                        currentQueueAttachment.forEach(function (file) {
                            if (file.name === name) {
                                newQueueAttachment.push(file.name);
                            }
                        });
                    });
                // Update the new file order in DB
                if (vm.listing.id) {
                    datacontext.updateAttachmentNames(vm.listing.id, newQueueAttachment).then(function (response) {
                        if (!response) {
                            return;
                        }
                        vm.listing.attachmentNames = response.data.attachmentNames;
                    });
                }
            }
        });

        vm.myDropzone.on('addedfile', function (file) {
            var i, len;
            var fileName = decodeURIComponent(file.name);
            if (vm.myDropzoneAttachment.files.length) {
                for (i = 0, len = vm.myDropzoneAttachment.files.length; i < len; i++) {
                    if (vm.myDropzoneAttachment.files[i].name === fileName) {
                        vm.fileOnServer = false;
                        vm.myDropzone.removeFile(file);
                        $scope.$apply(function () {
                            toaster.pop('note', 'Information!', 'There is already a file with the name "' + fileName + '"');
                        });
                        return;
                    }
                }
            }
            // Check if loaded from server and not duplicate file name
            if (!file.accepted && vm.fileOnServer) {
                vm.submitted = true;
                $scope.$apply(function () {
                    if ($scope.listingForm.$invalid && !vm.listing.id) {
                        vm.fileOnServer = false;
                        vm.myDropzone.removeFile(file);
                    }
                });
            }
        });

        vm.myDropzone.on('removedfile', function (file) {
            if (vm.fileOnServer) {
                var fileName = decodeURIComponent(file.name);
                datacontext.deleteFile(vm.listing, fileName).then(function (response) {
                    //workaround
                    var index = vm.listing.imageNames.indexOf(vm.listing.id + '/Images/' + fileName);

                    if (index !== -1) {
                        vm.listing.imageNames.splice(index, 1);
                    }
                });
            }
            vm.fileOnServer = true;
        });

        // After submit
        vm.myDropzone.on('sendingmultiple', function (files, xhr, formData) {
            $scope.$apply(function () {
                common.toggleSpinner({ show: true });
            });
            if (formData['listing'] === undefined || formData['listing'] === null) {
                if (vm.listing.inactiveStatusDate)
                    vm.listing.inactiveStatusDate = new Date(vm.listing.inactiveStatusDate);
                if (vm.listing.reservedStatusDate)
                    vm.listing.reservedStatusDate = new Date(vm.listing.reservedStatusDate);
                if (vm.listing.soldStatusDate)
                    vm.listing.soldStatusDate = new Date(vm.listing.soldStatusDate);

                formData.append('listing', JSON.stringify(vm.listing));
            }
        });

        // On success
        vm.myDropzone.on('successmultiple', function (files, response) {

            $scope.$apply(function () {
                if (!response || !response.id) {
                    common.returnHome();
                }
                vm.listing.id = response.id;
                vm.listing.imageNames = response.imageNames;
                vm.listing.listingId = response.listingId;
                vm.listing.countryId = response.countryId;
                common.toggleSpinner({ show: false });
            });
        });

        vm.myDropzone.on('error', function (file, errorMessage) {
            vm.fileOnServer = false;
            vm.myDropzone.removeFile(file);
            toaster.error({ title: 'Error!', body: errorMessage });
        });

        //myDropzoneAttachment
        vm.myDropzoneAttachment.on('addedfile', function (file) {
            var fileName = decodeURIComponent(file.name);

            // Create view attachment link
            if (vm.listing.id) {
                var a = document.createElement('a');
                a.setAttribute('href',
                    vm.apiPath + 'attachment?fileName=' + vm.listing.id + '/Attachments/' + fileName);
                a.innerHTML = 'View';
                file.previewTemplate.children[0].children[1].appendChild(a);
            }

            // Check for duplicate file name
            var i, len;
            if (vm.myDropzoneAttachment.files.length) {
                for (i = 0, len = vm.myDropzoneAttachment.files.length;
                    i < len - 1;
                    i++) // -1 to exclude current file
                {
                    if (vm.myDropzoneAttachment.files[i].name === fileName) {
                        vm.myDropzoneAttachment.files[i].name =
                            "[duplicate]" + vm.myDropzoneAttachment.files[i].name;
                    }
                }
            }
            if (vm.myDropzone.files.length) {
                for (i = 0, len = vm.myDropzone.files.length; i < len; i++) {
                    if (vm.myDropzone.files[i].name === fileName) {
                        vm.fileOnServer = false;
                        vm.myDropzoneAttachment.removeFile(file);
                        $scope.$apply(function () {
                            toaster.pop('note', 'Information!',
                                'There is already a file with the name "' + fileName + '"');
                        });
                        return;
                    }
                    if (vm.myDropzone.files[i].name === file.name) {
                        vm.fileOnServer = false;
                        vm.myDropzoneAttachment.removeFile(file);
                        $scope.$apply(function () {
                            toaster.pop('note', 'Information!',
                                'There is already a file with the name "' + file.name + '"');
                        });
                        return;
                    }
                }
            }
            // Check if loaded from server and not duplicate file name
            if (!file.accepted && vm.fileOnServer) {
                vm.submitted = true;
                $scope.$apply(function () {
                    // Form invalid
                    if ($scope.listingForm.$invalid && !vm.listing.id) {
                        vm.fileOnServer = false;
                        vm.myDropzoneAttachment.removeFile(file);
                        return;
                    }
                });
            }
        });

        vm.myDropzoneAttachment.on('removedfile', function (file) {
            if (vm.fileOnServer) {
                var fileName = decodeURIComponent(file.name);
                datacontext.deleteFile(vm.listing, fileName).then(function (response) {
                    //workaround
                    var index = vm.listing.attachmentNames.indexOf(vm.listing.id + '/Attachments/' + fileName);

                    if (index !== -1) {
                        vm.listing.attachmentNames.splice(index, 1);
                    }
                });
            }
            vm.fileOnServer = true;
        });

        // After submit
        vm.myDropzoneAttachment.on('sendingmultiple', function (files, xhr, formData) {
            $scope.$apply(function () {
                common.toggleSpinner({ show: true });
            });
            if (formData['listing'] === undefined || formData['listing'] === null) {
                if (vm.listing.inactiveStatusDate)
                    vm.listing.inactiveStatusDate = new Date(vm.listing.inactiveStatusDate);
                if (vm.listing.reservedStatusDate)
                    vm.listing.reservedStatusDate = new Date(vm.listing.reservedStatusDate);
                if (vm.listing.soldStatusDate)
                    vm.listing.soldStatusDate = new Date(vm.listing.soldStatusDate);

                formData.append('listing', JSON.stringify(vm.listing));
                if (!vm.listing.id) {
                    vm.listingCreated = false;
                }
            }
        });

        // On success
        vm.myDropzoneAttachment.on('successmultiple', function (files, response) {
            $scope.$apply(function () {
                if (!response || !response.id) {
                    common.returnHome();
                }
                else {
                    vm.listing.id = response.id;
                    vm.listing.attachmentNames = response.attachmentNames;
                    vm.listing.listingId = response.listingId;
                    vm.listing.countryId = response.countryId;
                    if (!vm.listingCreated) {
                        var a = document.createElement('a');
                        a.setAttribute('href', vm.apiPath + 'attachment?fileName=' + vm.listing.id + '/Attachments/' + files[0].name);
                        a.innerHTML = 'View';
                        files[0].previewTemplate.children[1].appendChild(a);
                        vm.listingCreated = true;
                    }
                    common.toggleSpinner({ show: false });
                }
            });

        });


        this.activate();
    }

    activate() {
        var vm = this;
        var initFunctions = [vm.init(), vm.initEquipmentTypes()];
        vm.common.activateController(initFunctions, "listing", { viewTitle: vm.viewTitle });
    }

    init() {
        var vm = this;
        if (vm.$stateParams.id) {
            return vm.datacontext.getListing(vm.$stateParams.id).then(function (response) {
                if (!response || !response.data) {
                    vm.common.returnHome();
                }

                vm.listing = response.data;

                // Making the Conditions Case insensitive.
                angular.forEach(vm.conditions, function (item) {
                    if (item.condition.toUpperCase() === vm.listing.condition.toUpperCase()) {
                        vm.listing.condition = item.condition;
                    }
                }
                );

                vm.initRoles();
                vm.changeCountry();

                if (vm.listing.soldStatusDate && vm.listing.soldStatusDate !== '') {
                    vm.listing.soldStatusDate = new Date(vm.listing.soldStatusDate);
                }
                if (vm.listing.reservedStatusDate && vm.listing.reservedStatusDate !== '') {
                    vm.listing.reservedStatusDate = new Date(vm.listing.reservedStatusDate);
                }
                else if (vm.listing.inactiveStatusDate && vm.listing.inactiveStatusDate !== '') {
                    vm.listing.inactiveStatusDate = new Date(vm.listing.inactiveStatusDate);
                }

                vm.listing.publishedStatusDate = moment(vm.listing.publishedStatusDate).format(vm.dateFormat);

                vm.listingBackup = angular.copy(vm.listing);

                // Getting the image details
                angular.forEach(vm.listing.imageNames, function (item) {
                    var existingFile = {
                        name: item.substring(item.indexOf('/', item.indexOf('/') + 1) + 1),
                        size: 123456,
                        status: Dropzone.ADDED,
                        accepted: true
                    };
                    vm.myDropzone.files.push(existingFile);
                    vm.myDropzone.emit('addedfile', existingFile);

                    // Getting file and attach the thumbnail
                    vm.datacontext.getFile(item).then(function (result) {
                        if (!result) {
                            return;
                        }
                        var existingFileStream = result.data;

                        vm.myDropzone.emit('thumbnail', existingFile, existingFileStream);
                        existingFile.previewTemplate.querySelector('input.FileId').value = item;
                    });
                });

                //Removing Duplicate File Names
                vm.listing.attachmentNames = vm.arrUnique(vm.listing.attachmentNames);

                // Getting the attachment details     
                angular.forEach(vm.listing.attachmentNames, function (item) {
                    var existingFile = {
                        name: item.substring(item.indexOf('/', item.indexOf('/') + 1) + 1),
                        size: 123456,
                        status: Dropzone.ADDED,
                        accepted: true
                    };
                    vm.myDropzoneAttachment.files.push(existingFile);
                    vm.myDropzoneAttachment.emit('addedfile', existingFile);
                    existingFile.previewTemplate.querySelector('input.FileId').value = item;
                });
            });
        }
        else {
            vm.listing.publishedStatusDate = moment().format(vm.dateFormat);
            vm.initUser();
            vm.listingBackup = angular.copy(vm.listing);
            return null;
        }
    }

    // Return Unique Array items
    arrUnique(arr) {
        var vm = this;
        var cleaned = [];
        arr.forEach(function (itm) {
            var unique = true;
            cleaned.forEach(function (itm2) {
                if (_.isEqual(itm, itm2)) {
                    unique = false;
                }
            });
            if (unique) {
                cleaned.push(itm);
            }
        });
        return cleaned;
    }

    initUser() {
        var vm = this;
        return vm.datacontext.getUser().then(function (response) {
            vm.listing.listingAgentId = response.data.userId;
            vm.listing.listingAgentName = response.data.displayName;
            vm.listing.country = response.data.searchCountry;
            vm.changeCountry();
        });
    }

    initRoles() {
        var vm = this;
        return vm.datacontext.getRoles().then(function (result) {
            if (result.data !== 'admin') {
                vm.datacontext.addListingTraffic(vm.listing.id, vm.$location.host()).then(function (trafficResponse) {
                    vm.listing.internalHits = vm.trafficResponse.data.internalHits;
                    vm.listing.externalHits = vm.trafficResponse.data.externalHits;
                });
            }
        });
    }

    initEquipmentTypes() {
        var vm = this;
        return vm.datacontext.getEquipmentTypes().then(function (response) {
            vm.equipmentTypes = response.data;
        });
    }

    save() {
        var vm = this;
        vm.common.toggleSpinner({ show: true });
        vm.submitted = true;
        if (!vm.$scope.listingForm.$invalid) {
            // Format Inactive Status Date
            if (vm.listing.listingStatus === 'Inactive') {
                vm.listing.inactiveStatusDate = new Date(vm.listing.inactiveStatusDate);
            }
            // Format Reserved Status Date
            if (vm.listing.listingStatus === 'Reserved') {
                vm.listing.reservedStatusDate = new Date(vm.listing.reservedStatusDate);
            }
            // Format Sold Status Date
            if (vm.listing.listingStatus === 'Sold') {
                vm.listing.soldStatusDate = new Date(vm.listing.soldStatusDate);
            }
            if (vm.listing.id) {
                vm.datacontext.updateListing(vm.listing).then(function (response) {
                    vm.$scope.listingForm.$setPristine();
                    vm.completeSave();
                });
            }
            else {
                vm.datacontext.addListing(vm.listing).then(function (response) {
                    if (!response || !response.data || !response.data.id) {
                        vm.common.returnHome();
                    }
                    vm.$scope.listingForm.$setPristine();
                    vm.completeSave('/listing/' + response.data.id);
                });
            }
        }
        else {
            vm.completeSave();
            vm.toaster.warning({
                title: 'Warning!',
                body: 'Validation failed. Please ensure that all the required fields are filled.'
            });
        }
    }

    completeSave(path) {
        var vm = this;
        if (path) {
            vm.$location.path(path);
        }

        vm.common.toggleSpinner({ show: false });
    }

    openInactiveDate($event) {
        var vm = this;
        $event.preventDefault();
        $event.stopPropagation();

        vm.openedInactiveDate = true;
    }

    openReservedDate($event) {
        var vm = this;
        $event.preventDefault();
        $event.stopPropagation();

        vm.openedReservedDate = true;
    }

    openSoldDate($event) {
        var vm = this;
        $event.preventDefault();
        $event.stopPropagation();

        vm.openedSoldDate = true;
    }

    searchAd(searchText) {
        const vm = this;
        if (searchText.length > 1) {
            return vm.datacontext.searchAd(searchText).then(function (response) {
                vm.userFilter = [];
                const data = response.data;
                data.forEach(function (entry) {
                    const fullName = entry.displayName.includes(",") ? entry.displayName : null;
                    const userId = entry.extension_edf736f3b97b4da995076002ed433bd7_sAMAccountName;
                    if (userId && fullName) {
                        const person = {
                            fullName: fullName,
                            userId: userId
                        };
                        vm.userFilter.push(person);
                        if (vm.userFilter.length > 14) {
                            return false;
                        }
                    }
                });
                return vm.userFilter;
            });
        }
        return false;
    }

    clearUnselected() {
        var vm = this;
        vm.$timeout(function () {
            if (!vm.listing.reservedFor) {//the model was not set by the typeahead
                vm.$scope.listingForm.reservedFor.$setViewValue('');
                vm.$scope.listingForm.reservedFor.$render();
            }
        }, 25); //a 250 ms delay should be safe enough
    }

    changeListingDetails() {
        var vm = this;
        vm.listing.inactiveStatusDate = '';
        vm.listing.reservedStatusDate = '';
        vm.listing.soldStatusDate = '';
        vm.listing.reservedFor = '';
        if (vm.listing.listingStatus === 'Inactive') {
            vm.listing.inactiveStatusDate = new Date();
        }
        else if (vm.listing.listingStatus === 'Reserved') {
            vm.listing.reservedStatusDate = new Date();
        }
        else if (vm.listing.listingStatus === 'Sold') {
            vm.listing.soldStatusDate = new Date();
        }
    }

    changeCountry() {
        var vm = this;
        if (vm.listing.country === 'Canada') {
            vm.stateProvinces = vm.caProvinces;
        }
        else if (vm.listing.country === 'United States') {
            vm.stateProvinces = vm.usStates;
        }
        else {
            vm.stateProvinces = [];
        }
    }

    newUsedChanged() {
        var vm = this;
        if (vm.listing.isNew) {
            vm.listing.condition = 'A - New 100%';
        }
        else {
            vm.listing.condition = '';
        }
    }

    reset() {
        var vm = this;
        vm.listing = angular.copy(vm.listingBackup);
        vm.$scope.listingForm.$setPristine();
    }

    cancel() {
        var vm = this;
        vm.common.returnHome();
    }

    backToSearch() {
        var vm = this;
        vm.$location.path('/search/');
    }
}

