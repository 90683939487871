export default class TrafficFactory {
	static config = {
		name: 'trafficFactory',
		serviceClass: TrafficFactory
	}
	
	constructor() {
		this.data = {
			isCached: false,
			mostPopularListings: [],
			lastTenSoldListings: [],
			lastTenListings: [],
			soldItemsToDate: [],
			soldItemsThisMonth: [],
			internalHitsThisWeek: 0,
			internalHitsThisMonth: 0,
			internalHitsThisYear: 0,
			internalHitsTotal: 0,
			externalHitsThisWeek: 0,
			externalHitsThisMonth: 0,
			externalHitsThisYear: 0,
			externalHitsTotal: 0,
			mostRecentListings: []
		};
	}

	ClearCache() {
		var vm = this;
		vm.data =
			{
				isCached: false,
				mostPopularListings: [],
				lastTenSoldListings: [],
				lastTenListings: [],
				soldItemsToDate: [],
				soldItemsThisMonth: [],
				internalHitsThisWeek: 0,
				internalHitsThisMonth: 0,
				internalHitsThisYear: 0,
				internalHitsTotal: 0,
				externalHitsThisWeek: 0,
				externalHitsThisMonth: 0,
				externalHitsThisYear: 0,
				externalHitsTotal: 0,
				mostRecentListings: []
			};
	}
	
	getIsCached() {
		var vm = this;
		return vm.data.isCached;
	}
	setIsCached() {
		var vm = this;
		vm.data.isCached = true;
	}
	getMostPopularListings() {
		var vm = this;
		return vm.data.mostPopularListings;
	}
	setMostPopularListings(mostPopularListings) {
		var vm = this;
		vm.data.mostPopularListings = mostPopularListings;
	}
	getLastTenSoldListings() {
		var vm = this;
		return vm.data.lastTenSoldListings;
	}
	setLastTenSoldListings(lastTenSoldListings) {
		var vm = this;
		vm.data.lastTenSoldListings = lastTenSoldListings;
	}
	getLastTenListings() {
		var vm = this;
		return vm.data.lastTenListings;
	}
	setLastTenListings(lastTenListings) {
		var vm = this;
		vm.data.lastTenListings = lastTenListings;
	}
	getsoldItemsToDate() {
		var vm = this;
		return vm.data.soldItemsToDate;
	}
	setsoldItemsToDate(soldItemsToDate) {
		var vm = this;
		vm.data.soldItemsToDate = soldItemsToDate;
	}
	getSoldItemsThisMonth() {
		var vm = this;
		return vm.data.soldItemsThisMonth;
	}
	setSoldItemsThisMonth(soldItemsThisMonth) {
		var vm = this;
		vm.data.soldItemsThisMonth = soldItemsThisMonth;
	}
	getInternalHitsThisWeek() {
		var vm = this;
		return vm.data.internalHitsThisWeek;
	}
	setInternalHitsThisWeek(internalHitsThisWeek) {
		var vm = this;
		vm.data.internalHitsThisWeek = internalHitsThisWeek;
	}
	getInternalHitsThisMonth() {
		var vm = this;
		return vm.data.internalHitsThisMonth;
	}
	setInternalHitsThisMonth(internalHitsThisMonth) {
		var vm = this;
		vm.data.internalHitsThisMonth = internalHitsThisMonth;
	}
	getInternalHitsThisYear() {
		var vm = this;
		return vm.data.internalHitsThisYear;
	}
	setInternalHitsThisYear(internalHitsThisYear) {
		var vm = this;
		vm.data.internalHitsThisYear = internalHitsThisYear;
	}
	getInternalHitsTotal() {
		var vm = this;
		return vm.data.internalHitsTotal;
	}
	setInternalHitsTotal(internalHitsTotal) {
		var vm = this;
		vm.data.internalHitsTotal = internalHitsTotal;
	}
	getExternalHitsThisWeek() {
		var vm = this;
		return vm.data.externalHitsThisWeek;
	}
	setExternalHitsThisWeek(externalHitsThisWeek) {
		var vm = this;
		vm.data.externalHitsThisWeek = externalHitsThisWeek;
	}
	getExternalHitsThisMonth() {
		var vm = this;
		return vm.data.externalHitsThisMonth;
	}
	setExternalHitsThisMonth(externalHitsThisMonth) {
		var vm = this;
		vm.data.externalHitsThisMonth = externalHitsThisMonth;
	}
	getExternalHitsThisYear() {
		var vm = this;
		return vm.data.externalHitsThisYear;
	}
	setExternalHitsThisYear(externalHitsThisYear) {
		var vm = this;
		vm.data.externalHitsThisYear = externalHitsThisYear;
	}
	getExternalHitsTotal() {
		var vm = this;
		return vm.data.externalHitsTotal;
	}
	setExternalHitsTotal(externalHitsTotal) {
		var vm = this;
		vm.data.externalHitsTotal = externalHitsTotal;
	}
	getMostRecentListings() {
		var vm = this;
		return vm.data.mostRecentListings;
	}
	setMostRecentListings(mostRecentListings) {
		var vm = this;
		vm.data.mostRecentListings = mostRecentListings;
	}
}