export default class HostConfig {
    static config = {
        name: 'hostConfig',
        serviceClass: HostConfig
    }

    constructor($q, $http, $location) {
        'ngInject';
        this.$q = $q;
        this.$http = $http;
        this.$location = $location;
    }

    serviceLocation() {
        var vm = this;
        const serviceUrls = [
            'http://localhost/SurplusInventory.API/api/',
            'https://surplusinventoryapi-t.ca.cloudcopapps.net/api/',
            'https://surplusinventoryapi-int-t.ca.cloudcopapps.net/api/',
            'https://surplusinventoryapi.ca.cloudcopapps.net/api/',
            'https://surplusinventoryapi-int.ca.cloudcopapps.net/api/'
        ];

        switch (vm.$location.host()) {
            case 'surplusinventory-t.ca.cloudcopapps.net':
                return serviceUrls[1];
            case 'surplusinventory-int-t.ca.cloudcopapps.net':
                return serviceUrls[2];
            case 'surplusinventory.ca.cloudcopapps.net':
                return serviceUrls[3];
            case 'surplusinventory-int.ca.cloudcopapps.net':
                return serviceUrls[4];
            default:
                return serviceUrls[0];
        }
    }
}