export default class duplicateEquipmentTypeModal {
    static CONFIG = {
        name: 'duplicateEquipmentTypeModal',
        controllerClass: duplicateEquipmentTypeModal
    };

    constructor($scope, $uibModalInstance, modalContext) {
        'ngInject';
        
        this.$uibModalInstance = $uibModalInstance;
        this.header = modalContext.header;
        this.equipmentTypeName = modalContext.equipmentTypeName;
    }

    close() {
        var vm = this;
        vm.$uibModalInstance.close();
    }

    cancel() {
        var vm = this;
        vm.$uibModalInstance.dismiss('cancel');
    }

    


}