export default class exportToExcelModal {
    static CONFIG = {
        name: 'exportToExcelModal',
        controllerClass: exportToExcelModal
    };

    constructor($scope, $uibModalInstance, modalContext) {
        'ngInject';
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.header = modalContext.header;
        this.exportColumns = modalContext.exportColumns;
        this.apiPath = modalContext.apiPath;
        this.listing = modalContext.listing;
        this.searchText = modalContext.searchText;
        this.isAllSelected = modalContext.isAllSelected;
    }

    close() {
        var vm = this;
        vm.$uibModalInstance.close();
    }

    cancel() {
        var vm = this;
        vm.$uibModalInstance.dismiss('cancel');
    }

    toggleAll() {
        var vm = this;
        angular.forEach(vm.$scope.exportColumns,
            function (value, key) {
                vm.exportColumns[key] = vm.$scope.isAllSelected;
            });
    }


}