import listingModel from '../../services/models/listingModel';
import moment from 'moment';
import $ from 'jquery';




export default class ViewListing {
	static CONFIG = {
		name: 'viewListing',
		route: '/listing/view/:id?',
		viewTemplate: require('./viewListing.html'),
		controllerClass: ViewListing,
		requireADLogin: true
	}

	constructor($scope, $filter, $q, $uibModal, $stateParams, $location, $http, $timeout, $window, common,
		datacontext, hostConfig) {
		'ngInject';
		this.title = 'viewListing';
		this.viewTitle = 'View Listing';
		this.$location = $location;
		this.parent = $scope.$parent;
		this.isInternal = false;
		this.listing = new listingModel();
		this.listingBackup = {};
		this.apiPath = hostConfig.serviceLocation() + 'listing/';
		this.fileOnServer = true;
		this.dateFormat = 'MMM D YYYY';
        this.datacontext = datacontext;
        this.emailAddress = '';
		this.common = common;
        this.$stateParams = $stateParams;
        this.$uibModal = $uibModal;
		this.activate();
	}

	activate() {
		var vm = this;
		var initFunctions = [vm.init(), vm.initUser()];

		vm.common.activateController(initFunctions, "viewListing", { viewTitle: vm.viewTitle });
	}

	init() {
		var vm = this;
		return vm.datacontext.getListing(vm.$stateParams.id).then(function (listingResponse) {
			if (!listingResponse || !listingResponse.data)
				vm.common.returnHome();

			vm.listing = listingResponse.data;

			vm.initRoles();
			vm.datacontext.getContactInfo(vm.listing.country).then(function (response) {
				vm.emailAddress = response.data;
			});

			if (vm.listing.listingStatus === 'Sold')
				vm.showSold = true;
			else if (vm.listing.listingStatus === 'Reserved') {
				vm.showReserved = true;
				vm.listing.reservedStatusDate = new Date(vm.listing.reservedStatusDate);
			}
			else if (vm.listing.listingStatus === 'Inactive')
				vm.listing.inactiveStatusDate = moment(vm.listing.inactiveStatusDate).format(vm.dateFormat);
			vm.listing.publishedStatusDate = moment(vm.listing.publishedStatusDate).format(vm.dateFormat);
			vm.listingBackup = angular.copy(vm.listing);
		});
	}

	initUser() {
		var vm = this;
		return vm.datacontext.getAuthenticated().then(function (response) {
			vm.isInternal = response.data;
		});
	}

	initRoles() {
		var vm = this;
		return vm.datacontext.getRoles().then(function (result) {
			if (!result)
				return;

			vm.role = result.data;
			if (vm.role !== 'admin') {
				vm.datacontext.addListingTraffic(vm.listing.id, vm.$location.host());
			}
		});
	}

	selectImage(index) {
		$('#imageCarousel').carousel(index);
	}

	prevImage()
	{
		$('#imageCarousel').carousel('prev');
	}

	nextImage()
	{
		$('#imageCarousel').carousel('next');
	}

	backToSearch() {
		var vm = this;
		vm.$location.path('/search/');
	}

	printElement(elem, append, delimiter) {
		var domClone = elem.cloneNode(true);
		var $printSection = document.getElementById("printSection");
		if (!$printSection) {
			$printSection = document.createElement("div");
			$printSection.id = "printSection";
			document.body.appendChild($printSection);
		}
		if (append !== true) {
			$printSection.innerHTML = "";
		}
		else if (append === true) {
			if (typeof (delimiter) === "string") {
				$printSection.innerHTML += delimiter;
			}
			else if (typeof (delimiter) === "object") {
				$printSection.appendChlid(delimiter);
			}
		}
		$printSection.appendChild(domClone);
	}

	printPage() {
		printElement(document.getElementById("printDiv"));
		window.print();
	}

	showContactInfo() {
        var vm = this;
        let modalData = {
            emailAddress: vm.emailAddress
        };
        vm.$uibModal.open({
            template: require('./contactInfoModal.html'),
            controller: 'contactInfoModal',
            controllerAs: '$ctrl',
            size: 'md',
            resolve: {
                modalContext: () => modalData
            }
        });
	}
}
