export default class searchResultFactory {
	static config = {
		name: 'searchResultFactory',
		serviceClass: searchResultFactory
	}

	constructor() {
		this.data =
			{
				isCached: false,
				searchResult: [],
				listStatus: [],
				equipmentTypes: [],
				isInternal: false,
				searchText: '',
				searchListing: {},
				currentPage: 1,
				itemsPerPage: 10,
				reservedNames: []
			};
	}

	ClearCache() {
		var vm = this;
		vm.data =
			{
				isCached: false,
				searchResult: [],
				listStatus: [],
				equipmentTypes: [],
				isInternal: false,
				searchText: '',
				searchListing: {},
				currentPage: 1,
				itemsPerPage: 10,
				reservedNames: []
			};
	}
	SetCache(isCached, searchResult, role, listStatus, equipmentTypes, isInternal, searchText, searchListing, currentPage, itemsPerPage, reservedNames) {
		var vm = this;
		vm.data =
			{
				isCached: isCached,
				searchResult: searchResult,
				role: role,
				listStatus: listStatus,
				equipmentTypes: equipmentTypes,
				isInternal: isInternal,
				searchText: searchText,
				searchListing: searchListing,
				currentPage: currentPage,
				itemsPerPage: itemsPerPage,
				reservedNames: reservedNames
			};
	}

	getIsCached() {
		var vm = this;
		return vm.data.isCached;
	}
	setIsCached() {
		var vm = this;
		vm.data.isCached = true;
	}
	getSearchResult() {
		var vm = this;
		return vm.data.searchResult;
	}
	setSearchResult(searchResult) {
		var vm = this;
		vm.data.searchResult = searchResult;
	}
	getRole() {
		var vm = this;
		return vm.data.role;
	}
	setRole(role) {
		var vm = this;
		vm.data.role = role;
	}
	getListStatus() {
		var vm = this;
		return vm.data.listStatus;
	}
	setListStatus(listStatus) {
		var vm = this;
		vm.data.listStatus = listStatus;
	}
	getEquipmentTypes() {
		var vm = this;
		return vm.data.equipmentTypes;
	}
	setEquipmentTypes(equipmentTypes) {
		var vm = this;
		vm.data.equipmentTypes = equipmentTypes;
	}
	getIsInternal() {
		var vm = this;
		return vm.data.isInternal;
	}
	setIsInternal(isInternal) {
		var vm = this;
		vm.data.isInternal = isInternal;
	}
	getSearchText() {
		var vm = this;
		return vm.data.searchText;
	}
	setSearchText(searchText) {
		var vm = this;
		vm.data.searchText = searchText;
	}
	getSearchListing() {
		var vm = this;
		return vm.data.searchListing;
	}
	setSearchListing(searchListing) {
		var vm = this;
		vm.data.searchListing = searchListing;
	}
	getSearchCurrentPage() {
		var vm = this;
		return vm.data.currentPage;
	}
	setCurrentPage(currentPage) {
		var vm = this;
		vm.data.currentPage = currentPage;
	}
	getListingsPerPage() {
		var vm = this;
		return vm.data.itemsPerPage;
	}
	setItemsPerPage(itemsPerPage) {
		var vm = this;
		vm.data.itemsPerPage = itemsPerPage;
	}
	getReservedNames() {
		var vm = this;
		return vm.data.reservedNames;
	}
	setReservedNames(reservedNames) {
		var vm = this;
		vm.data.reservedNames = reservedNames;
	}
}
