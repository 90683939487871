import angular from 'angular';

export default {
		shellControllerInit: function(controllerConfig)
		{
			//Controller registration, edit with care
			angular.module('app')
				.config(($controllerProvider)=>
				{
					// noinspection BadExpressionStatementJS
					'ngInject';
					$controllerProvider.register(controllerConfig.name, controllerConfig.controllerClass);
				});
		},

		controllerInit: function(controllerConfig)
		{
			//Controller registration, edit with care
			angular.module('app')
				.config(($stateProvider, $controllerProvider)=>
				{
					// noinspection BadExpressionStatementJS
					'ngInject';
					$stateProvider
						.state(controllerConfig.name,
							{
								url: controllerConfig.route,
								template: controllerConfig.viewTemplate,
								controller: controllerConfig.name,
								resolve: controllerConfig.initServiceCalls,
								requireADLogin: controllerConfig.requireADLogin,
								controllerAs: '$ctrl'
							});
					$controllerProvider.register(controllerConfig.name, controllerConfig.controllerClass);
				});
		},

		factoryInit: function(factoryConfig)
		{
			angular.module('app').factory(factoryConfig.name, factoryConfig.factoryClass);
		},

		serviceInit: function(serviceConfig)
		{
			angular.module('app').service(serviceConfig.name, serviceConfig.serviceClass);
		},

		directiveInit: function(directiveConfig)
		{
			angular.module('app').directive(directiveConfig.default.name(), directiveConfig.default.directive);
		}
	}