import AppConfig from '../app.config';

export default function logging($provide, $httpProvider) {
    // noinspection BadExpressionStatementJS
    'ngInject';
    
    // Exception Handler Section
    $provide.decorator('$exceptionHandler', 
        function ($delegate, logger) {
            // noinspection BadExpressionStatementJS
            'ngInject';
            return function(exception, cause){
                $delegate(exception, cause);

                if(AppConfig.appPrefix && !exception.message || exception.message.indexOf(AppConfig.appPrefix) === 0)
                    return;

                let errorData = { exception: exception, cause: cause};
                let msg = AppConfig.appPrefix + exception.message;
                logger.logError(msg, errorData, 'app', true);
            }
        }
    );

    // HTTP Logging Section
    $httpProvider.interceptors.push('httpLoggingInterceptor');
    
    $provide.factory('httpLoggingInterceptor', 
        function ($q, logger) {
            // noinspection BadExpressionStatementJS
            'ngInject';
            return {
                'response': function (response) {
                    switch (response.config.method) {
                        case "POST":
                            //logger.logSuccess("Post Successful");
                            break;
                        case "PUT":
                            logger.logSuccess("Update Successful");
                            break;
                        case "DELETE":
                            logger.logSuccess("Delete Successful");
                            break;
                        default:
                            logger.logSuccess("Get Successful");
                    }

                    return response;
                },
                'responseError': function (rejection) {
                    if (!rejection.data)
                        rejection.data = {};

                    let errorMsg = (rejection.data.message || rejection.message);

                    let msg = AppConfig.appPrefix + 'Error retrieving data from web API.';
                    if (errorMsg !== undefined && errorMsg)
                        msg += ' ' + errorMsg;
                    logger.logError(msg, rejection);

                    return $q.reject(rejection);
                }
            }
        }
    );
}