export default class listingModel {
	constructor() {
		this.id = '';
		this.listingId = null;
		this.country = '';
		this.countryId = '';
		this.isNew = false;
		this.size = '';
		this.description = '';
		this.equipmentType = '';
		this.manufacturer = '';
		this.modelNumber = '';
		this.serialNumber = '';
		this.skidNumber = '';
		this.condition = '';
		this.conditionComment = '';
		this.ansi = null;
		this.isSweet = null;
		this.gridLocation = '';
		this.location = '';
		this.area = '';
		this.stateProvince = '';
		this.otherInfo = '';
		this.listingStatus = '';
		this.sapEquipmentNumber = null;
		this.notificationNumber = '';
		this.creditCoding = '';
		this.publishedStatusDate = null;
		this.inactiveStatusDate = null;
		this.soldStatusDate = null;
		this.isInternal = false;
		this.listingAgentId = '';
		this.listingAgentName = '';
		this.reservedStatusDate = null;
		this.reservedFor = '';
		this.imageNames = [];
		this.attachmentNames = [];
		this.internalHits = 0;
		this.externalHits =0;
	}
}